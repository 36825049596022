html, body * {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-size: 16px !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #ff4136, #143B38);
}
::-webkit-scrollbar-thumb:hover {
  background: black; 
}



@media only screen and (max-width: 1200px){
  h1 {
    font-size: 22px !important;
  }
  h2 {
    font-size: 21px !important;
  }
  h3 {
    font-size: 20px !important;
  }
  h4 {
    font-size: 17px !important;
  }
  h5 {
    font-size: 16px !important;
  }
  p, li, a {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 600px){
  /*nav ul, nav .number {
    display: none;
  }
  .ham_icon {
    display: block;
  }*/
  h1 {
    font-size: 18px !important;
  }
  h2 {
    font-size: 17px !important;
  }
  h3 {
    font-size: 16px !important;
  }
  h4 {
    font-size: 15px !important;
  }
  h5 {
    font-size: 15px !important;
  }
  p, li, a {
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 425px){
  /*nav ul, nav .number {
    display: none;
  }
  .ham_icon {
    display: block;
  }*/
  .home .flip {
    width: 300px;
    height: 300px;
  }
  h1 {
    font-size: 13px !important;
  }
  h2 {
    font-size: 12px !important;
  }
  h3 {
    font-size: 11px !important;
  }
  h4 {
    font-size: 10px !important;
  }
  h5 {
    font-size: 10px !important;
  }
  p, li, a {
    font-size: 10px !important;
  }
}
