* {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
}
.App {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  isolation: isolate;
  position: relative;
  overflow-x: hidden !important;
  overflow-y: visible;
}
.App::after {
  content: '';
  background-image: url('./images/sonatres_background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: absolute;
  opacity: 0.2;
  height: 100%;
  width: 100%;
  z-index: -1;
}

iframe#webpack-dev-server-client-overlay{display:none!important}
nav {
  background: white;
  gap: 1rem;
  width: 100%;
  height: 10vh;
  align-self: flex-start;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgb(192, 192, 192), 0 6px 20px 0 rgba(255, 255, 255, 0.479);
  z-index: 1;
  padding-left: .5rem;
  padding-right: .5rem;
  transition: all .25s ease-in-out;
  border-bottom: .1px solid #143B38;
}
.nav_logo {
  height: 100%;
  flex: 3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
nav img {
  height: 60%;
  width: max(15%, 30px);
  object-fit: contain;
  cursor: pointer;
  flex: 3;
  object-position: left;
 }

nav ul { 
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  align-content: center;
  flex: 6;
}
.number {
  height: 100%;
  width: max(10%, 100px);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  position: relative;
  flex: 2;
}
.number_list {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 50%;
  translate: -50%;
  width: fit-content;
  padding: .5rem;
  gap: .5rem;
  height: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  background: #ff4136;
  color: white;
  border-radius: 10px;
  transition: all .25s ease-in-out;
}
.number:hover .number_list {
  height: 30vh;
  opacity: 1;
  pointer-events: all;
}
.number h5 {
  text-align: center;
  color: #143B38;
}

.number_list h5 a{
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.number_list h5 a:hover {
  color: #143B38;
}

main {
  margin-top: 10vh;
  padding: 0 !important;
  width: 100%;
  min-height: 90vh;
  padding: .5rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
 }
 .home {
  width: 100%;
  min-height: 50vh;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0;
  padding-bottom: 2rem;
}
.home_prods {
  min-height: 30vh;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
  padding: 1rem;
}
.filter, .filter_mobile {
  height: 5vh;
  width: 85%;
  border-radius: 20px;
  background-color: #ff4136;
  color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  justify-self: flex-start !important;
  margin-top: 0 !important;
}
.filter_mobile {
  border-radius: 0;
  width: 100%;
}
.filter a, .filter_mobile a {
  text-decoration: none;
  color: white;
}
.filter h3, .filter_mobile h3 {
  cursor: pointer;
  transition: all ease-in-out 0.25s;
  height: 100%;
  display: flex;
  align-items: center;
}
.filter h3 {
  position: relative;
}
.filter_sub, .filter_sub_mobile {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 100%;
  height: 0vh;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  background: #ff4136;
  z-index: 2;
  transition: all 0.25s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.35) 20px 5px 15px;
}
.filter_sub {
  left: 50%;
  translate: -50%;
  width: 25vw;
  flex-direction: column;
  border-radius: 30px;
}
.filter_sub_mobile {
  left: 0;
  width: 100%;
  flex-direction: row;
  border-radius: 0;
}
.filter h3 span:hover, .filter_mobile h3 span:hover {
  color: #143B38;
}
.filter h3:hover .filter_sub {
  pointer-events: all;
  opacity: 1;
  height: 30vh;
}
.filter_mobile h3:hover .filter_sub_mobile {
  pointer-events: all;
  opacity: 1;
  height: 10vh;
}
.filter div h5, .filter_mobile div h5 {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter div a, .filter_mobile div a {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter div h5:first-child {
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
}
.filter div h5:last-child {
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

.filter div h5:hover, .filter_mobile div h5:hover {
  background: white;
}
.filter div h5:hover *, .filter_mobile div h5:hover * {
  color: #143B38;
  animation: shake .25s ease-in-out;
}
@keyframes shake {
  0% {transform: rotate(-10deg);}
  50% {transform: rotate(10deg);}
  100% {transform: rotate(0);}
}

 .App nav li {
  list-style: none;
  cursor: pointer;
  font-weight: bolder;
  position: relative;
  transition: all 0.25 ease-in-out;
 }

 nav > ul li:hover {
  color: #ff4136;
 }

 nav > ul li:not(#isActive):hover:after {
  width: 100%;
 }

 nav > ul li:after {
  content: " ";
  display: block;
  height: 1px;
  width: 0;
  position: absolute;
  transition: all 0.25s ease-in-out;
 }

 nav > ul li:after {
  background: #ff4136;
 }

 #cover_image {
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.2;
 }
.flip {
  background-color: transparent;
  width: 350px;
  height: 350px;
  border-radius: 50px;
}
.flip_inner {
  border-radius: 50px;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  
}
.flip:hover .flip_inner {
  transform: rotateY(180deg);
  transition: all ease-in-out 0.5s;
  scale: 1.1;
}

.flip_front, .flip_back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 50px;
}

.flip_front {
  opacity: 1 !important;
  color: black;
  cursor: pointer;
  border: #ff4136 5px solid;
}

.flip_back {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: black;
  transform: rotateY(180deg);
  padding: .5rem;
}
.flip_back h3 {
  color: #ff4136;
}
.flip_front img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: calc(50px - .5rem) !important;
  z-index: 5;
}
 .home_text {
  display: flex;
  justify-content: center;
  align-items: center;
 }
.about {
  min-height: 90vh;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
 }
 .about_text {
  width: max(50%, 350px);
  height: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.55);
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1.2px);
  -webkit-backdrop-filter: blur(1.2px);
  border: 1px solid rgba(255, 255, 255, 0.3);
 }
 .about_text div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0;
  padding: 1rem;
  line-height: 2;
  width: 100%;
  color: #143B38;
  letter-spacing: 1px;
 }
 .about_text div span{
  display: inline-block;
  height: min-content;
  font-size: 105%;
  color: #ff4136;
  font-style: italic;
  background: white;
  box-shadow: 0 4px 30px #143B38;
  border: none;
 }

 
 .about .map {
  width: max(40%, 330px);
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
 }
 .about iframe {
  opacity: 0.5;
  width: 100%;
  min-height: 50vh;
  transition: all 0.25s ease-in-out;
  box-shadow: 0px 4px 24px 1px rgba(0, 0, 0, 0.28);
  border-radius: 20px; 
}
 .about iframe:hover {
  opacity: 1;
 }

 .contact {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  align-self: center;
  width: max(50%, 300px);
  min-height: 80vh;
  gap: 1rem;
  padding: 1rem;
  margin: 1rem;
  border-radius: 20px;
  background: white;
  box-shadow: 0 4px 8px 0 rgb(192, 192, 192), 0 6px 20px 0 rgba(255, 255, 255, 0.479);
}
 .contact_first {
  gap: 1rem;
  height: 20%;
  width: 90%;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
 }
 #nn, #contact_email {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
 }
 #nn {
  width: 40%;
  background-color: whitesmoke !important;
 }

 #contact_email {
  border: none;
  width: 90% !important;
  border-radius: 0.2rem;
  background-color: whitesmoke;
 }
 .contact_text {
  width: 90%;
  min-height: 10%;
  text-align: center;
 }
 .contact_text p {
  color: #ff4136;
 }

 .isDisabled {
  color: currentColor;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
  text-decoration: none;
}
.contact a {
  color: white;
  text-decoration: none;
}
.contact input {
  outline: none;
}
#contact_message {
  outline: none;
  padding: .5rem;
  line-height: 1rem;
  font-size: 15px;
  box-shadow: 0 4px 8px 0 rgb(192, 192, 192), 0 6px 20px 0 rgba(255, 255, 255, 0.479);
}
@keyframes inputShadow {
  0% {
    box-shadow: 0 4px 8px 0 rgb(192, 192, 192), 0 6px 20px 0 rgba(255, 255, 255, 0.479);
  }
  100% {
    box-shadow: 0 4px 8px 0 rgb(192, 192, 192), 0 6px 20px 0 #7c9bff;
  }
}
#contact_message:focus {
  animation: inputShadow ease-in-out 0.5s forwards;
}
#contact_btn {
  background: #143B38;
  position: relative;
  transition: all ease-in-out 0.5s;
}
#contact_btn:hover {
  scale: 1.1;
  color: #ff4136;
}
.home_text {
  width: 50% !important;
}

.ham_icon {
  cursor: pointer;
}

.ham_menu {
  position: fixed;
  top: 10vh;
  right: 0;
  width: 50%;
  height: 90vh;
  z-index: 2;
  background: rgba(255, 255, 255, 0.55);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1.2px);
  -webkit-backdrop-filter: blur(1.2px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.ham_menu ul {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}
.ham_menu ul li {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: bolder;
  cursor: pointer;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}
.ham_menu ul li:hover {
  background: #143B38;
  color: white;
}
.ham_menu ul li {
  box-shadow: 1px 1px 5px .5px white;
}

@media only screen and (max-width: 1200px){
  .number a {
    font-size: 15px;
  }
}
@media only screen and (max-width: 800px) {
  .number {
    position: unset;
  }
  .number:hover .number_list {
    height: 10vh;
    flex-direction: row;
  }
}
@media only screen and (max-width: 600px){
  /*nav ul, nav .number {
    display: none;
  }
  .ham_icon {
    display: block;
  }*/
  .ham_menu {
    width: 50%;
  }
  .number a {
    font-size: 12px;
  }
  #about_icon {
    height: 30px;
    width: 30px;
  }
  #nn {
    width: 100%;
  }
  .number:hover .number_list {
    height: 11vh;
    flex-direction: row;
  }
}
@media only screen and (max-width: 425px){
  /*nav ul, nav .number {
    display: none;
  }
  .ham_icon {
    display: block;
  }*/
  .number a {
    font-size: 10px;
  }
  
  #about_icon {
    height: 20px;
    width: 20px;
  }
  #nn {
    width: 100%;
  }

  .about_icons {
    bottom: calc(10vh - 5px);
    left: 50%;
    translate: -50%;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    padding: 5px;
  }
}
@media only screen and (orientation: landscape) {
  .number:hover .number_list {
    height: 50vh;
    flex-direction: column;
  }
}
@media screen and (width <= 1030px) and (orientation: landscape) {
  nav {
    height: 20vh;
  }
  .number:hover .number_list {
    height: 50vh;
    flex-direction: column;
  }
  .ham_menu {
    top: 20vh;
    height: 80vh;
  }
  main {
    margin-top: 20vh;
    min-height: 100vh;
  }
  .filter_mobile, .filter {
    height: 10vh;
  }
  .filter_mobile h3:hover .filter_sub_mobile {
    height: 15vh;
  }
}
@media only screen and (width <= 800px) and (orientation: landscape) {
  .number {
    position: unset;
  }
  .number:hover .number_list {
    height: 10vh;
    flex-direction: row;
  }
}
@media only screen and (height <= 500px) and (width > 800px) and (orientation: landscape) {
  .number_list {
    width: max-content;
  }
}




