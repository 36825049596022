.Page {
    width: 100%; 
    min-width: 100vh; 
    display: flex; 
    flex-direction: column; 
    justify-content: space-evenly; 
    align-items: flex-start;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    overflow: hidden !important;
}
.Page_entete {
    width: 100%;
    height: 10vh;
    position: fixed;
    top: 0;
    z-index: 20;
    background: linear-gradient(45deg, #ff4136, #143B38);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-blend-mode: hue;
    box-shadow: 0px 4px 24px 1px rgba(0, 0, 0, 0.28);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}
.Page_entete_icons {
    height: 100%; 
    width: 10%;
    padding: .5rem;
    gap: .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#Page_icon {
    color: white;
    height: 25px;
    width: 25px;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
}
#Page_icon:hover {
    color: #ff4136;
    height: 33px;
    width: 33px;
}
.Page_entete_icons:first-child #Page_icon:hover {
    color: #143B38;
}
#Page_title {
    color: white;
    font-style: italic;
    text-decoration: underline black;
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    margin: 0 !important;
}

.Langue {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.Langue p {
    color: #ff4136;
    text-align: center;
    font-weight: bolder;
    transition: all ease-in-out 5s;
}
.Langue ul {
    position: absolute;
    top: 100%;
    width: 100%;
    height: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: .5rem;
    padding: .5rem;
    opacity: 0;
    pointer-events: none;
    background: #ff4136;
    border-radius: 5px;
    z-index: 3;
    transition: all ease-in-out .5s;
}
.Langue li {
    height: 100%;
    width: 100;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: white;
}
.Langue span {
    transition: all ease-in-out .25s;
}
.Langue li:hover span {
    color: #143B38;
    scale: 1.5;
}
.Langue:hover ul {
    height: 10vh;
    opacity: 1;
    pointer-events: all;
}

footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 20vh;
    height: auto;
    gap: .5rem;
    padding: 0;
    background: rgba(255, 255, 255, 0.48);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.2px);
    -webkit-backdrop-filter: blur(1.2px);
    border: 1px solid rgba(255, 255, 255, 0.31);
}
footer h5 {
    color: #143B38;
}
.footer_first {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding: .25rem;
    gap: .25rem;
}
.footer_Llinks, .footer_Hlinks, .footer_icons, .footer_pos, .footer_website {
    min-height: 15vh;
    width: max(15%, 280px);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: .5rem;
    padding: .5rem;
    background: rgba(202, 202, 202, 0.48);
    box-shadow: 0 4px 50px 20px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.2px);
    -webkit-backdrop-filter: blur(1.2px);
    border-radius: 20px;
}
.footer_Hlinks, .footer_Llinks {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
footer a {
    color: #143B38;
    text-decoration: none;
}
footer li {
    list-style: none;
    font-style: italic;
    position: relative;
    cursor: pointer;
    width: 80%;
    transition: all ease-in-out .25s;
}
footer li:hover {
    scale: 1.1;
    color: #ff4136;
}
footer li::after {
    color: #ff4136;
    font-weight: 700;
    position: absolute;
    bottom: -100%;
    opacity: 0;
    width: 100%;
    transition: all ease-in-out .5s;
    pointer-events: none;
}
#footer_home .footer_Hlinks li::after, #footer_treillis .footer_Hlinks li::after,
#footer_panneaux .footer_Hlinks li::after, #footer_piquets .footer_Hlinks li::after {
    content: '↗↗';
}
#footer_piquets .footer_Hlinks li:nth-child(2)::after, #footer_treillis .footer_Hlinks li:nth-child(2)::after,
#footer_panneaux .footer_Hlinks li:nth-child(2)::after {
    content: '↖↖';
}
#footer_home .footer_Hlinks li:nth-child(2)::after {
    content: '⬆⬆';
}
#footer_treillis .footer_Hlinks li:nth-child(3)::after {
    content: '⬆⬆';
}
#footer_panneaux .footer_Hlinks li:nth-child(4)::after {
    content: '⬆⬆';
}
#footer_piquets .footer_Hlinks li:nth-child(5)::after {
    content: '⬆⬆';
}

#footer_home .footer_Llinks li::after{
    content: '⬆⬆';
}
#footer_treillis .footer_Llinks li::after, #footer_panneaux .footer_Llinks li::after,
#footer_piquets .footer_Llinks li::after {
    content: '↖↖';
}
footer li:hover::after {
    bottom: 0;
    opacity: 1;
}
.footer_pos h5 {
    letter-spacing: 0.1rem;
    text-align: center;
    transition: all ease-in-out.5s;
}
.footer_pos:hover * {
    color: #ff4136;
}
.footer_pos:hover #footer_icon {
    scale: 1.1;
    color: #ff4136 !important;
    box-shadow: none;
    transform: none;
}
.footer_icons, .footer_Hlinks, .footer_Llinks {
    gap: 1rem;
}
.footer_icons h5 {
    width: 100%;
    text-align: center;
}
.footer_website h5 {
    text-align: center;
    position: relative;
}
.footer_website a {
    text-decoration: none;
    color: #143B38;
    width: 100%;
    letter-spacing: .1rem;
}
.footer_website h5::after {
    content: '';
    height: .1px;
    width: 0;
    background: #ff4136;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all ease-in-out .5s;
}
.footer_website h5:hover::after {
    width: 100%;
}
footer h5:hover a {
    color: #ff4136;
}
#footer_icon {
    height: 30px;
    width: 30px;
    cursor: pointer;
    transition: all .1s ease-in-out;
    border: none !important;
    border-radius: 0 !important;
    transition: all ease-in-out .25s;
    box-shadow: 3px 1px black ;
    transform: perspective(400px) rotateY(-25deg);
}
#footer_icon:hover {
    scale: 1.1;
    color: #ff4136 !important;
    box-shadow: none;
    transform: none;
}
.insta_icon {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%) !important;
    color: white;
}
footer h6 {
    background: black;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: relative;
    gap: .25rem;
    padding: 1rem;
    box-shadow: 2px -2px 30px 10px rgb(100, 100, 100);
}
footer h6::before {
    content: '';
    position: absolute;
    width: 100%;
    height: .1px;
    top: 0;
    left: 0;
}

@media only screen and (max-width: 600px) {
    .Page_entete_icons {
        width: 15%;
    }
    #Page_icon {
        height: 20px;
        width: 20px;
    }
}

@media only screen and (max-width: 425px){
    .Page_entete_icons {
        width: 20%;
    }
    #Page_icon {
        height: 15px;
        width: 15px;
    }
}
@media screen and (width <= 1500px) and (orientation: landscape) {
    .Langue:hover ul {
        height: 20vh;
    }
}
