.Treillis, .Panneau {
    position: absolute;
    top: 10vh;
    min-height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    position: relative;
    overflow-x: hidden !important;
}
.vid_cover {
    object-fit: cover;
    opacity: 0.2;
    z-index: -2;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}
#Treillis_title {
    margin: 1rem;
}
.Treillis h1, .Treillis h3 {
    color: #ff4136;
    align-self: flex-start;
}
.Treillis h3 {
    text-decoration: underline;
}
.Treillis_second_avs h1, .Treillis_second_avs h3 {
    color: #143B38;
}
.Treillis_first, .Treillis_second {
    height: 50%;
    width: 95%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 1rem;
    padding: 1rem 0;
    border: 0.5px solid #ff4136;
    border-radius: 20px;
}
.Treillis_second:hover {
    background: white;
}
.Treillis_first_figures {
    width: clamp(300px, 90%, 700px);
    /*width: max(50%, 300px);*/
    height: 100%;
}
.Treillis_first_figures_full {
    height: 90vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(242, 242, 242);
    z-index: 10;
}
.Treillis_first_figures_full img {
    height: 85vh;
    width: 100%;
    object-fit: contain;
    cursor: pointer;
    box-shadow: 0px 4px 24px 1px rgba(0, 0, 0, 0.28);
}
.Treillis_first_figures img {
    height: 60vh;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0px 4px 24px 1px rgba(0, 0, 0, 0.28);
    cursor: pointer;
}/*
.Treillis_first_figures_smalls {
    background: rgb(242, 242, 242);
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
}
.Treillis_first_figures_smalls img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    cursor: pointer;
}*/
.slide {
    box-shadow: 0px 4px 24px 1px rgba(0, 0, 0, 0.28);
    border-radius: 20px;
}
figure {
    gap: 0;
    display: flex;
    flex-direction: column;
}
figcaption {
    height: 100%;
    margin: 0;
    color: white;
    text-align: center;
    background: #143B38;
}

#fullScreenImage {
    position: absolute;
    top: 0;
    left: 0;
    height: 70vh;
    width: 100%;
    z-index: 2;
    object-fit: contain;
    background-color: black;
    border-style: none;
    border-radius: 0;
}
.Treillis_first_dscrp {
    text-indent: 5%;
    line-height: 2rem;
    letter-spacing: .5px;
    width: clamp(300px, 90%, 700px);
    /*width: max(45%, 300px);*/
    height: 100%;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    box-shadow: 0px 4px 24px 1px rgba(0, 0, 0, 0.28);
    background: rgb(242, 242, 242);
    gap: 1rem;
    padding: 1rem;
    border-radius: 20px;
}
.Treillis_first_dscrp p {
    line-height: 2rem;
}
.Treillis_first_dscrp_vid {
    text-indent: 0;
    width: max(150px, 25%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: .1rem;
}
.Treillis_first_dscrp a:first-child{
    background: linear-gradient(45deg, #ff4136, #143B38);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: none;
    position: relative;
}
.Treillis_first_dscrp a:first-child:hover {
    background: none;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
    color: #ff4136;
}
.Treillis_first_dscrp a:first-child:after {
    content: '';
    width: 0;
    height: 1px;
    background: #ff4136;
    position: absolute;
    left: 0;
    top: 75%;
    transition: all ease-in-out .25s;
}
.Treillis_first_dscrp a:first-child:hover::after {
    width: 100%;
}
.Treillis_first_dscrp ul {
    align-self: flex-start;
    width: 80%;
}
.Treillis_first_dscrp #tst {
    color: #ff4136;
}
/*
.Treillis_second ul {
    letter-spacing: .5px;
    width: max(40%, 300px);
}
.Treillis_second_app ul {
    width: 90%;
    padding: 1rem;
}
*/
.Treillis_second_app, .Treillis_second_avs {
    width: clamp(300px, 80%, 700px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem;
    line-height: 2rem;
    letter-spacing: .5px;
    text-indent: 1rem;
}
.Treillis_second_avs {
    background: #ff4136;
    color: white;
    border-radius: 20px;
}
.Treillis_second_avs img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.Treillis_second_pdf {
    width: max(250px, 80%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
}
.Treillis_second_pdf h5 {
    position: relative;
    background: linear-gradient(45deg, #ff4136, #143B38);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all ease-in-out .25s;
    text-align: center;
}
.Treillis_second_pdf h5 a {
    text-decoration: none;
    color: black;
}
.Treillis_second_pdf h5:hover a {
    color: #ff4136;
}
.Treillis_second_pdf h5:hover{
    background: transparent;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
}
.Treillis_second_pdf h5::after {
    content: '';
    width: 0;
    position: absolute;
    top: 100%;
    left: 0;
    height: 1px;
    background: red;
    transition: all ease-in-out .25s;
}
.Treillis_second_pdf h5:hover::after {
    width: 100%;
}
#icon {
    cursor: pointer;
    transition: all ease-in-out .25s;
    box-shadow: 2px .2px black ;
    transform: perspective(400px) rotateY(-25deg);
    color:#143B38;
}
#icon:hover {
    box-shadow: none;
    transform: none;
    color: #ff4136;
    scale: 1.2;
}
#vid_main {
    height: 100vh;
    width: 95%;
    border-radius: 20px;
    position: relative;
}
#vid_main video {
    width: 100% !important;
    border-radius: 20px;
    box-shadow: 0px 4px 24px 1px rgba(0, 0, 0, 0.28);
}
.Treillis .map {
    min-height: 30vh;
    width: 95%;
    border-radius: 20px;
}
.Treillis .map iframe {
    width: 100%;
    box-shadow: 0px 4px 24px 1px rgba(0, 0, 0, 0.28);
    border-radius: 20px;
    min-height: 30vh;
    opacity: 0.5;
    transition: all .25s ease-in-out;
}
.Treillis .map iframe:hover {
    opacity: 1;
}

.Treillis #footer_uno {
    color: #ff4136;
}
.Treillis #footer_dos {
    color: #143B38;
}

@media only screen and (max-width: 425px){ 
    .Treillis_first, .Treillis_second {
        width: 98%;
        padding: 5px 0;
    }
}

@media (orientation: landscape) {
    .Page_entete {
        height: 20vh;
    }
    #Treillis_title {
        margin-top: 2rem;
    }
  }



